@tailwind base;
@tailwind components;
@tailwind utilities;

@config "../tailwind.config.js";

@font-face {
  font-family: 'Apercu Pro';
  src: url('../public/fonts/apercu_regular_pro.woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Apercu Pro';
  src: url('../public/fonts/apercu_medium_pro.woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Apercu Pro';
  src: url('../public/fonts/apercu_bold_pro.woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Tiempos Headline';
  src: url('../public/fonts/TiemposHeadlineWeb-Semibold.woff2');
  font-weight: 400;
}

@layer base {
  body {
    @apply font-sans font-normal text-nero-2 leading-10 bg-white;
  }
  h1 {
    @apply font-serif font-bold text-5xl leading-[58px] py-2 text-nero-2;
  }
  h2 {
    @apply font-serif font-medium text-3xl leading-9 py-1  text-nero-2;
  }
  h3 {
    @apply font-sans text-lg leading-5 text-nero-2;
  }
  a {
    @apply font-sans text-base text-nero-2 hover:text-nero-1;
  }
  p {
    @apply font-sans text-base leading-5 text-nero-2 opacity-90;
  }
  input[type='button'] {
    @apply bg-white;
  }
}